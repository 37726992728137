export enum ToastMessages {
  loginAndVerify = 'Please login and verify mobile number to continue',
  cartAmtAbove1k = 'Your cart amount must be 1000 or above',
  enterCode = 'Please enter code',
  couponAppliedSuccessfully = 'Coupon applied sucessfully',
  couponRemovedSuccessfully = 'Coupon removed sucessfully',
  errorOccured = 'Some Error Occured',
  enterPincode = 'Enter valid Pincode',
  removedFromWishlist = 'Removed from Wishlist.',
  otpSentSuccessfullly = 'OTP sent successfully',
  somethingWentWrong = 'Something went wrong',
  expDateError = 'Expiry date should be greater than current date',
  addedToCart = 'Added to Cart.',
  addedToWishlist = 'Added to Wishlist.',
  backInStockNotification = 'Will Notify You When This Product In Stock',
  productAddedToCart = 'Product Added To Cart',
  productAddedToCartSuccessfully = 'Product Added to Cart Successfully.',
  nowFollowing = 'You are now following ',
  itemAlreadyInWishlist = 'Item Already in Wishlist',
  storyLikedSuccessfully = 'Story liked Successfully.',
  storyUnlikedSuccessfully = 'Story unliked Successfully.',
  itemNotInWishlist = 'Item Not In Wishlist',
  addedToWishlistSuccessfully = 'Added to Wishlist Successfully',
  productAddedToWishlist = 'Product Added to Wishlist',
  removedFromWishlistSuccessfully = 'Removed from Wishlist Successfully.',
  loggedOutSuccessfully = 'Logged out Successfully',
  couponRemoved = 'Coupon removed !',
  selectOption = 'Please select any option(s)',
  selectAnOption = 'Please select an option',
  openProductDescription = 'will open product description.',
  selectSeller = 'Please Select Seller',
  selectGender = 'Please Select Gender',
  addressSavedSuccessfully = 'Address added successfully',
  addressUpdatedSuccessfully = 'Address updated successfully',
  imageTypeNotValid = 'Image type not valid.',
  uploadImagesSelectedProducts = 'Please upload image for all selected products',
  thankyou = 'Thank you',
  noChange = 'No Change',
  verifyMobileNo = 'Verify Mobile Number',
  startedFollowing = 'You started following ',
  stoppedFollowing = 'You stopped following ',
  addressDeletedSuccessfully = 'Address Deleted Successfully',
  defaultAddressUpdated = 'Default Address Updated',
  loginSuccessful = 'Login Successful',
  errorInAccountInfo = 'Something went wrong while fetching your account info. Please re login and try again',
  maxPrebookingAllowed = 'Maximum 5 pre bookings allowed',
  loginUnsuccessful = 'Login Unsuccessful',
  errorMsg = "Some error occured.",
  wishlistAddedSuccessfully = "Added to Wishlist Successfully",
  notifyOnProductInStock = "Will Notify You When This Product In Stock",
  invalidPincode = "Please enter valid pincode",
  notifyMeSuccess = "Thank you for your interest in this Item. You will be Notified by Email when it becomes available.",
  addAddress = "Add Address on checkout page",
  editAddress = "Edit Address on checkout page",
  outOfStock = "Item out of stock",
  loginMessage = "Please login and verify mobile number to continue",
  enterValidOtp = "Please enter a valid OTP",
  profileUpdate = "Profile updated successfully.",
  verifyMobile = "Please verify mobile number",
  noTransactionInWallet = "Currently there are no transactions in your Wallet.",
  enterMobile = "Please enter mobile number",
  invalidMobile = "Please enter valid mobile number",
  verifyPhoneToContiue = "Please verify mobile number to continue",
  profileUpdatedSuccessfully = "Profile updated successfully.",
  pleaseSelectGender = "Please Select Gender",
  pleaseSelectAnyOptions = "Please select any option(s)",
  pleaseUploadImageForAllSelectedProducts = "Please upload image for all selected products",
  fileTooLarge = "Error uploading file - file too large (5mb max size).",
  imageSizeExceeded = "image size exceeded.",
  pleaseProvideReasonForAllSelectedProducts = "Please provide reason for all selected products",
  addItemToUnlockFreebie = "Please add items in your cart to unlock more freebies",
  applying = "APPLYING...",
  removing = "REMOVING...",
  validPincode = "Pincode should be 6 digits",
  pleaseLogin = "Please login to continue",
  updatedDefaultAddress = "Default Address Updated",
  selectAddress = "Please select address",
  nonServiceableCity = "Not servicable yet,try different city",
  enterVaildEmail = "Please enter valid email address",
  userNotLoggedIn = "You are not logged in.",
  userNotLoggedInToDeleteAccount = "You are not logged in. Please login and try again.",
  accountDeletedSuccessfully = "Account deleted successfully"
}
